import React, {useState} from 'react'
import styled from 'styled-components/macro'
// import Cookies from 'universal-cookie'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useNavigate} from 'react-router-dom'
import {Typography} from 'routingAndDispatch/Routes/routesStyles'

// const cookies = new Cookies()

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  background-color: #263238;
  color: white;
  height: 40px;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: calc(16 / 14 * 1rem);
  margin-right: 10px;
`
const MenuIcon = styled(FontAwesomeIcon)`
  font-size: calc(26 / 16 * 1rem);
  transition: all 0.3s;
  transform: rotate(
    ${(p: {menuopen: string}) => (p.menuopen === 'true' ? '180' : '0')}deg
  );
`

// const AdminArea = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
// `
const AdminAreaSection = styled.div`
  display: flex;
  padding: 15px;
  svg {
    align-self: center;
    margin-right: 15px;
  }
  :hover {
    background-color: #e7e7e7;
    cursor: pointer;
  }
`

const UserName = styled.div`
  font-size: calc(16 / 14 * 1rem);
  text-align: right;
  margin-top: 4px;
  margin-right: 20px;
`

// const UserCompany = styled.div`
//   font-size: 1.6rem;
//   margin-top: 4px;
//   text-align: right;
//   margin-left: 10px;
// `

const IconText = styled.div`
  font-size: calc(15 / 14 * 1rem);
`

// const RightSide = styled.div`
//   display: flex;
//   margin-right: 15px;
// `

// const StyledHR = styled.hr`
//   width: calc(100% - 10px);
// `

const AdminMenu = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  height: ${(p: {menuopen: string}) => (p.menuopen === 'true' ? '53px' : '0')};
  opacity: ${(p: {menuopen: string}) => (p.menuopen === 'true' ? '1' : '0')};
  overflow: hidden;
  position: absolute;
  top: 40px;
  right: 15px;
  color: #616261;
  background-color: white;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.29);
  z-index: 6;
  padding: 0 20px;
`

const NameAndIcon = styled.div`
  cursor: pointer;
  display: flex;
  margin-right: 10px;
`

type Props = {
  creds: {
    userName: string
    userCompany: string
    auth: string
  }
}

const Header = (props: Props) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const navigate = useNavigate()
  return (
    <HeaderContainer>
      <Typography fontSize={25} color="white">
        Management Console
      </Typography>
      <NameAndIcon onClick={() => setMenuOpen(!menuOpen)}>
        <UserName>
          <Icon icon="user"></Icon>
          {props.creds ? props.creds.userName : ''}
        </UserName>
        <MenuIcon icon="angle-down" menuopen={menuOpen.toString()}></MenuIcon>
      </NameAndIcon>

      <AdminMenu menuopen={menuOpen.toString()}>
        <AdminAreaSection onClick={() => navigate('/logout')}>
          <Icon icon="sign-out-alt"></Icon>
          <IconText>Log Out</IconText>
        </AdminAreaSection>
      </AdminMenu>
    </HeaderContainer>
  )
}
export default Header
