import * as React from 'react'
import styled from 'styled-components/macro'
import useOpening1 from 'opening/hooks/useOpening1'
import useOpening4 from 'opening/hooks/useOpening4'
import useOpening5 from 'opening/hooks/useOpening5'
import {nFormatter} from 'common/helpersNumber'
import {useStore} from 'common/useStore'
import {Table, X, SpinnerGap} from 'phosphor-react'
import Tooltip from 'common/components/Tooltip'
import {Dropdown} from 'semantic-ui-react'
import AssetsFilter from './AssetsFilter'
import {QUERIES} from 'common/constants'
import {useMapStore} from 'map/useMapStore'
import {checkAddDeleteEditAssetLowerLevels} from 'common/helpers'
import {ascendColors} from 'common/GlobalStyles'

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  background-color: transparent;
  .ui.selection.active.dropdown {
    outline: none;
    border: 1px solid var(--tertiary);
    box-shadow: 0px 0px 6px 1px var(--asc-coolgray) !important;
  }
  .ui.selection.active.dropdown .menu {
    outline: none;
    border: 1px solid var(--tertiary);
    border-top: 0px;
  }
`

const ItemBadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: center;
  background-color: transparent;
  width: 100%;
`

const ItemBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 40px;
  min-height: 1em;
  padding: 0.37em;
  line-height: 1em;
  justify-content: center;
  border-radius: 500rem;
  background-color: var(--secondary);
  box-shadow: 1px 1px 1px var(--asc-coolgray);
  color: ${p => p.fontOnSecondary} !important;
  font-weight: bold;
`

const ItemLabel = styled.div`
  display: flex;
  font-size: 14px;
  margin-left: 10px;
`

const IconContainer = styled.div`
  display: flex;
  padding-top: 10px;
  padding-bottom: 7px;
  background-color: transparent;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0px 10px;
`

const IconHolder = styled.span`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 25px;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  flex-direction: column;
  cursor: pointer;
  background-color: ${props =>
    props.active ? 'var(--primary)' : 'transparent'};
  :svg {
    color: ${props => (props.active ? 'var(--primary)' : 'transparent')};
    fill: ${props => (props.active ? 'var(--primary)' : 'transparent')};
  }
  &&:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`

const StyledDropdown = styled(Dropdown)`
  &&& {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    height: 40px;
    padding: 0;
    width: 210px;
    font-weight: 700;
    color: #000000;
    flex-grow: 1;
    .menu {
      max-height: fit-content;
    }

    .text {
      font-weight: 700 !important;
      color: #000000;
    }
    .divider {
      width: 100%;
      margin-right: 35px;
    }
    i {
      cursor: pointer;
      position: absolute;
      height: 11px;
    }
    :focus,
    :active {
      outline: none;
      border: 1px solid var(--tertiary);
      box-shadow: 0px 0px 6px 1px var(--asc-coolgray) !important;
    }
  }
`

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  user-select: none; /*prevents highlighting the dropdown items*/
  justify-content: space-between;
  min-height: 25px;
`

const DesktopTooltip = styled.div`
  display: none;
  @media ${QUERIES.tabletAndUp} {
    display: flex;
  }
`

const ListHeader = ({
  filteredAssets,
  onAssetsFilter,
  assetsStatus,
  setTabSelected,
  selectedGroups,
  selectedProjects,
  selectedLocations,
  selectedZones,
  tabSelected,
  unassignedDevices,
}) => {
  const {data: opening1} = useOpening1()
  const {data: opening4} = useOpening4()
  const {data: opening5} = useOpening5()
  const assets = opening1?.assets || []
  const groups = opening4?.groups || []
  const projects = opening4?.projects || []
  const locations = opening5?.locations || []
  const zones = opening5?.zones || []
  const setGridOpen = useStore(state => state.setGridOpen)
  const setAssetListOpen = useStore(state => state.setAssetListOpen)
  const assetListOpen = useStore(state => state.assetListOpen)
  const userConfig = useStore(state => state.userConfig)
  const canAddDeleteEditAssetLowerLevels = checkAddDeleteEditAssetLowerLevels()
  const showGrid = () => {
    setGridOpen(true)
    setAssetListOpen(false)
  }

  const assetsDropdownItem = (
    <ItemContainer id="assets" data-testid="viewselector-assets">
      <ItemLabel>Assets</ItemLabel>
      <ItemBadgeContainer>
        <ItemBadge
          showFilter={filteredAssets && filteredAssets.length !== assets.length}
          fontOnSecondary={ascendColors.fontOnSecondary}
        >
          {assetsStatus === 'loading' ? (
            <SpinnerGap size={14} weight="bold">
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="2s"
                from="0 0 0"
                to="360 0 0"
                repeatCount="indefinite"
              ></animateTransform>
            </SpinnerGap>
          ) : (
            <span>
              {filteredAssets && filteredAssets.length !== assets.length
                ? `${nFormatter(filteredAssets.length, 0)} of ${nFormatter(
                    assets.length,
                    0,
                  )}`
                : nFormatter(assets.length)}
            </span>
          )}
        </ItemBadge>
      </ItemBadgeContainer>
    </ItemContainer>
  )

  const groupsDropdownItem = (
    <ItemContainer id="groups" data-testid="viewselector-groups">
      <ItemLabel>Groups</ItemLabel>
      <ItemBadgeContainer>
        <ItemBadge fontOnSecondary={ascendColors.fontOnSecondary}>
          {selectedGroups?.length > 0
            ? `${nFormatter(selectedGroups.length, 0)} of ${nFormatter(
                groups.length,
                0,
              )} `
            : nFormatter(groups.length, 0)}
        </ItemBadge>
      </ItemBadgeContainer>
    </ItemContainer>
  )

  const projectsDropdownItem = (
    <ItemContainer id="projects" data-testid="viewselector-projects">
      <ItemLabel>Projects</ItemLabel>
      <ItemBadgeContainer>
        <ItemBadge fontOnSecondary={ascendColors.fontOnSecondary}>
          {selectedProjects?.length > 0
            ? `${nFormatter(selectedProjects.length, 0)} of ${nFormatter(
                projects.length,
                0,
              )} `
            : nFormatter(projects?.length, 0)}
        </ItemBadge>
      </ItemBadgeContainer>
    </ItemContainer>
  )

  const locationsDropdownItem = (
    <ItemContainer id="locations" data-testid="viewselector-locations">
      <ItemLabel>Locations</ItemLabel>
      <ItemBadgeContainer>
        <ItemBadge fontOnSecondary={ascendColors.fontOnSecondary}>
          {selectedLocations?.length > 0
            ? `${nFormatter(selectedLocations.length, 0)} of ${nFormatter(
                locations.length,
                0,
              )} `
            : nFormatter(locations.length, 0)}
        </ItemBadge>
      </ItemBadgeContainer>
    </ItemContainer>
  )

  const zonesDropdownItem = (
    <ItemContainer id="zones" data-testid="viewselector-zones">
      <ItemLabel>Zones</ItemLabel>
      <ItemBadgeContainer>
        <ItemBadge fontOnSecondary={ascendColors.fontOnSecondary}>
          {selectedZones?.length > 0
            ? `${nFormatter(selectedZones.length, 0)} of ${nFormatter(
                zones.length,
                0,
              )} `
            : nFormatter(zones.length, 0)}
        </ItemBadge>
      </ItemBadgeContainer>
    </ItemContainer>
  )

  const hierarchyDropdownItem = (
    <ItemContainer id="hierarchy" data-testid="viewselector-hierarchy">
      <ItemLabel>Hierarchy</ItemLabel>
      <ItemBadgeContainer></ItemBadgeContainer>
    </ItemContainer>
  )

  const unassignedDropdownItem = (
    <ItemContainer id="unassigned" data-testid="viewselector-unassigneddevices">
      <ItemLabel>Unassigned Devices</ItemLabel>
      <ItemBadgeContainer>
        <ItemBadge fontOnSecondary={ascendColors.fontOnSecondary}>
          <span>{nFormatter(unassignedDevices?.length, 0)}</span>
        </ItemBadge>
      </ItemBadgeContainer>
    </ItemContainer>
  )

  let listOptions =
    userConfig?.UserRole?.idUserRole !== 1 &&
    userConfig?.UserRole?.idUserRole !== 11
      ? [
          {
            key: 'Assets',
            text: ``,
            value: `assets`,
            image: assetsDropdownItem,
          },
          {
            key: 'Groups',
            text: ``,
            value: 'groups',
            image: groupsDropdownItem,
          },
          {
            key: 'Projects',
            text: ``,
            value: 'projects',
            image: projectsDropdownItem,
          },
        ]
      : [
          {
            key: 'Assets',
            text: ``,
            value: `assets`,
            image: assetsDropdownItem,
          },
          {
            key: 'Groups',
            text: ``,
            value: 'groups',
            image: groupsDropdownItem,
          },
          {
            key: 'Projects',
            text: ``,
            value: 'projects',
            image: projectsDropdownItem,
          },
          {
            key: 'Locations',
            text: ``,
            value: 'locations',
            image: locationsDropdownItem,
          },
          {
            key: 'Zones',
            text: ``,
            value: 'zones',
            image: zonesDropdownItem,
          },
          {
            key: 'Unassigned Devices',
            text: ``,
            value: 'unassigned',
            image: unassignedDropdownItem,
          },
          {
            key: 'Hierarchy',
            text: ``,
            value: 'hierarchy',
            image: hierarchyDropdownItem,
          },
        ]

  if (!canAddDeleteEditAssetLowerLevels) {
    listOptions = listOptions.filter(item => item.value !== 'unassigned')
  }

  React.useEffect(() => {
    if (!assetListOpen && tabSelected !== 'assets') {
      setTabSelected('assets')
    }
  }, [assetListOpen, setTabSelected, tabSelected])

  return (
    <React.Fragment>
      <HeaderContainer>
        <StyledDropdown
          data-cy={'assets-dropdown'}
          data-testid="cardlist-viewselector"
          fluid
          selection
          options={listOptions}
          onChange={(e, data) => {
            setTabSelected(data.value)
            useMapStore.setState({showAssetMarkers: true})
          }}
          value={tabSelected}
        />

        <IconContainer>
          {tabSelected === 'assets' && (
            <AssetsFilter onAssetsFilter={onAssetsFilter} />
          )}
          <DesktopTooltip>
            <Tooltip
              tooltip={'Open Asset Grid'}
              position={'top center'}
              trigger={
                <IconHolder
                  data-cy={'grid-view'}
                  onClick={showGrid}
                  data-testid="cardlist-opengrid"
                >
                  <Table size={19} color={`var(--primary)`} />
                </IconHolder>
              }
            />
          </DesktopTooltip>
          <Tooltip
            tooltip={'Close Asset List'}
            position={'top center'}
            trigger={
              <IconHolder
                onClick={() => setAssetListOpen(false)}
                data-testid="cardlist-close"
              >
                <X size={19} color={'var(--primary)'} />
              </IconHolder>
            }
          />
        </IconContainer>
      </HeaderContainer>
    </React.Fragment>
  )
}
export default ListHeader
