export const VERSION = '3.42'
import {object, string, number} from 'zod'
import {ascendColors} from './GlobalStyles'
let temedaURI = `../`
let temedaAPIURI = `../api/`
let temedaWS = `..:11502/`
let azureImageBase = ''
let reportingServer = ''
let dynamicGroupsURI = ''
let tranztecSsoBase = ''
let appFlavor: 'att' | 'temeda' | 'ascend' = 'temeda'
let tranztecImportScript = ''

if (window && window.location && window.location.hostname) {
  if (
    window.location.hostname.includes('qa-ascendfleet.netlify.app') ||
    window.location.hostname.includes('qa.ascendfleet.net') ||
    window.location.hostname.includes('qa.ascendfleet.com')
  ) {
    //** point qa to dev */
    // appFlavor = 'ascend'
    // temedaURI = 'https://qa.ascendfleet.com/images/'
    // temedaAPIURI = 'https://qa.ascendfleet.com/api/'
    // temedaWS = 'https://dev.temeda.com:11502/'
    // azureImageBase = 'https://temedadevwebimagessa.blob.core.windows.net'
    // reportingServer =
    //   'https://reports.dev.ascendfleet.com/TemedaReports/rdPage.aspx'
    // dynamicGroupsURI = 'https://dev-temeda-api-management.azure-api.net/gql'
    // tranztecSsoBase = 'https://qa.viaboards.com'
    // tranztecImportScript = 'https://attachments.viaboards.io/embed/embeds-qa.js'

    //** point qa to prod */
    appFlavor = 'ascend'
    temedaURI = 'https://web.ascendfleet.com/'
    temedaAPIURI = 'https://web.ascendfleet.com/api/'
    temedaWS = 'https://www.temeda.com:11502/'
    azureImageBase = 'https://temedasmbwebimagessa.blob.core.windows.net'
    reportingServer =
      'https://reports.ascendfleet.com/TemedaReports/rdPage.aspx'
    dynamicGroupsURI = `https://temeda.azure-api.net/gql`
    tranztecSsoBase = 'https://viaboards.com'
    tranztecImportScript =
      'https://attachments.viaboards.io/embed/embeds-prod.js'
  } else if (
    window.location.hostname === 'localhost' ||
    window.location.hostname.includes('dev.ascendfleet.net') ||
    window.location.hostname.includes('dev.ascendfleet.com') ||
    // window.location.hostname.includes('qa-ascendfleet.netlify.app') ||
    // window.location.hostname.includes('qa.ascendfleet.net') ||
    // window.location.hostname.includes('qa.ascendfleet.com') ||
    window.location.hostname.includes('app.dev.temeda.com')
  ) {
    //** point localhost and dev to dev server */
    appFlavor = window.location.hostname.includes('app.dev.temeda.com')
      ? 'temeda'
      : 'ascend'
    temedaURI = 'https://dev.temeda.com/'
    temedaAPIURI = 'https://dev.temeda.com/api/'
    temedaWS = 'https://dev.temeda.com:11502/'
    azureImageBase = 'https://temedadevwebimagessa.blob.core.windows.net'
    reportingServer =
      'https://reports.dev.ascendfleet.com/TemedaReports/rdPage.aspx'
    dynamicGroupsURI = 'https://dev-temeda-api-management.azure-api.net/gql'
    tranztecSsoBase = 'https://qa.viaboards.com'
    tranztecImportScript = 'https://attachments.viaboards.io/embed/embeds-qa.js'

    //** point localhost and dev to prod temporarily */
    // appFlavor = 'ascend'
    // temedaURI = 'https://web.ascendfleet.com/'
    // temedaAPIURI = 'https://web.ascendfleet.com/api/'
    // temedaWS = 'https://www.temeda.com:11502/'
    // azureImageBase = 'https://temedasmbwebimagessa.blob.core.windows.net'
    // reportingServer =
    //   'https://reports.ascendfleet.com/TemedaReports/rdPage.aspx'
    // dynamicGroupsURI = `https://temeda.azure-api.net/gql`
    // tranztecSsoBase = 'https://viaboards.com'
    // tranztecImportScript =
    //   'https://attachments.viaboards.io/embed/embeds-prod.js'

    //** for testing with a local dev server
    // temedaURI = 'http://localhost:49192/'
    //temedaAPIURI = 'http://localhost:49192/'
    // reportingserver = "http://10.241.88.8/logi-reports/rdPage.aspx"
  } else if (
    window.location.hostname.includes('app.ascendfleet.net') ||
    window.location.hostname.includes('app.ascendfleet.com')
  ) {
    appFlavor = 'ascend'
    temedaURI = 'https://web.ascendfleet.com/'
    temedaAPIURI = 'https://web.ascendfleet.com/api/'
    temedaWS = 'https://www.temeda.com:11502/'
    azureImageBase = 'https://temedasmbwebimagessa.blob.core.windows.net'
    reportingServer =
      'https://reports.ascendfleet.com/TemedaReports/rdPage.aspx'
    dynamicGroupsURI = `https://temeda.azure-api.net/gql`
    tranztecSsoBase = 'https://viaboards.com'
    tranztecImportScript =
      'https://attachments.viaboards.io/embed/embeds-prod.js'
  } else if (window.location.hostname.includes('temeda.com')) {
    if (window.location.hostname.includes('att.eams.temeda.com')) {
      appFlavor = 'att'
      temedaURI = 'https://att.eams.temeda.com/images/'
      temedaAPIURI = 'https://att.eams.temeda.com/api/'
      temedaWS = 'https://att.temeda.com:11502/'
      azureImageBase = 'https://temedaattwebimagessa.blob.core.windows.net'
      reportingServer =
        'https://reports.att.temeda.com/TemedaReports/rdPage.aspx'
      dynamicGroupsURI = `https://temeda.azure-api.net/gql-att`
      tranztecSsoBase = 'https://viaboards.com'
      tranztecImportScript =
        'https://attachments.viaboards.io/embed/embeds-prod.js'
    } else {
      appFlavor = 'temeda'
      temedaURI = 'https://www.temeda.com/'
      temedaAPIURI = 'https://www.temeda.com/api/'
      temedaWS = 'https://www.temeda.com:11502/'
      azureImageBase = 'https://temedasmbwebimagessa.blob.core.windows.net'
      reportingServer = 'https://reports.temeda.com/TemedaReports/rdPage.aspx'
      dynamicGroupsURI = `https://temeda.azure-api.net/gql`
      tranztecSsoBase = 'https://viaboards.com'
      tranztecImportScript =
        'https://attachments.viaboards.io/embed/embeds-prod.js'
    }
  }
}

//export const TEMEDA_URI = temedaURI;
export const TEMEDA_URI = temedaURI
export const TEMEDA_API_URI = temedaAPIURI
export const TEMEDA_WS = temedaWS
export const TEMEDA_NETAPI_URI = temedaURI + 'netapi/'
export const TEMEDA_REPORTING_SERVER = reportingServer
export const DYNAMIC_GROUPS_API_URI = dynamicGroupsURI
export const TRANZTEC_SSO_BASE = tranztecSsoBase
export const TRANZTEC_IMPORT_SCRIPT = tranztecImportScript
export const APP_FLAVOR: 'att' | 'temeda' | 'ascend' = appFlavor

export const GOOGLE_KEYS = {
  client: 'gme-temedallc',
  libraries: ['places', 'geometry', 'drawing', 'directions'],
}

export const LEFT_MENU_WIDTH_EXPANDED = 258
export const LEFT_MENU_PADDING = 37
export const LEFT_MENU_WIDTH_COLLAPSED = 58
export const HEADING_HEIGHT = 46

export const AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE = azureImageBase
export const REPORTING_SERVER = reportingServer

export const BREAKPOINTS = {
  phoneMax: 600,
  tabletMax: 950,
  laptopMax: 1300,
}
export const QUERIES = {
  phoneAndDown: `(max-width: ${BREAKPOINTS.phoneMax / 16}rem)`,
  phoneAndUp: `(min-width: ${BREAKPOINTS.phoneMax / 16}rem)`,
  tabletAndUp: `(min-width: ${BREAKPOINTS.tabletMax / 16}rem)`,
  laptopAndUp: `(min-width: ${BREAKPOINTS.laptopMax / 16}rem)`,
}
export const WINDOWTABS: {[key: string]: Window | null} = {assetDashboard: null}

export const WEATHER_KEYS = {
  id: 'LHUzdIDqmvg6hwMd8yqsN',
  secret: 'JfN6QvW5grJzvOVXRbN5NSGd86TgqT9ev7PNv1SC',
  //keys api for weather app
}
export type MarkerColor = {
  1: string
  2: string
  3: string
  4: string
  5: string
  6: string
  7: string
  8: string
  9: string
  10: string
  11: string
  12: string
  unknown: string
}
export const MARKER_COLORS: MarkerColor = {
  1: ascendColors.vermilion, //--asc-vermilion
  2: ascendColors.darkolive, //--primary
  3: ascendColors.gold, //--asc-gold
  4: ascendColors.tan, //--asc-tan
  5: ascendColors.moss, //--asc-moss
  6: ascendColors.lime, //--secondary
  7: ascendColors.geoforceLightBlue, //--asc-denim
  8: ascendColors.blue, //--asc-blue
  9: ascendColors.purple, //--asc-purple
  10: ascendColors.flamingo, //--asc-flamingo
  11: ascendColors.brown, //--asc-brown
  12: '#000000',
  unknown: '#000000',
}

export const MapColors = [
  ascendColors.brown, //-asc-brown
  ascendColors.moss, //--asc-moss
  ascendColors.blue, //--asc-blue
  ascendColors.flamingo, //--asc-flamingo
  ascendColors.purple, //--asc-purple
]

export const ANIMATION_DURATION_MILLISECONDS = 10000
export const UPDATE_INTERVAL_MILLISECONDS = 20000

export const emailSchema = object({
  email: string().email(),
})

export const phoneSchema = object({
  phone: string().regex(/^[0-9]{10,15}$/),
})
export const LatitudeSchema = object({
  latitude: number().min(-90).max(90),
})
export const LongitudeSchema = object({
  longitude: number().min(-180).max(180),
})

// export function validateEmail(email: string) {
//   const re =
//     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//   return re.test(email)
// }

export const WEEKDAYOPTIONS = [
  {
    key: 1,
    value: 1,
    text: 'Monday',
  },
  {
    key: 2,
    value: 2,
    text: 'Tuesday',
  },
  {
    key: 3,
    value: 3,
    text: 'Wednesday',
  },
  {
    key: 4,
    value: 4,
    text: 'Thursday',
  },
  {
    key: 5,
    value: 5,
    text: 'Friday',
  },
  {
    key: 6,
    value: 6,
    text: 'Saturday',
  },
  {
    key: 7,
    value: 7,
    text: 'Sunday',
  },
]

export const INDEFINITELYINTOFUTURE = '2100-01-01T00:00:00'
export const GRACE_PERIOD_MINUTES_FOR_LATE_CALC = 15

export const MAX_MESSAGE_CHAR_LIMIT = 160

//route editing page row heights
export const SEPARATOR_HEIGHT_PX = 45
export const SEGMENT_HEIGHT_PX = 60
export const HEADER_HEIGHT_PX = 60
